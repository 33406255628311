import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { VerifyComponent } from './verify/verify.component';
import { CreateAccountComponent } from './createAccount/createAccount.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';


const accountRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'create', component: CreateAccountComponent },
    { path: 'forgotpassword', component: ForgotPasswordComponent },
    { path: 'passwordreset/:userId/:token', component: PasswordResetComponent },
    { path: 'verify/:userId/:token', component: VerifyComponent },
    { path: '', redirectTo: '/account/login', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forChild(accountRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AccountRoutingModule { }