import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../components/auth/auth-guard.service';
import {UserPagesComponent} from './user/userPages.component';
import {OrgAdminGuard} from '../components/auth/org-admin-guard.service';
import {CaregiverGuard} from '../components/auth/caregiver-guard.service';
import {FacilityAdminGuard} from '../components/auth/facility-admin-guard.service';
import {SiteAdminGuard} from '../components/auth/site-admin-guard.service';

const appRoutes: Routes = [
    {
        path: 'user',
        loadChildren: () => import('./user/userPages.module').then(m => m.UserPagesModule),
        // component: UserPagesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'caregiver',
        loadChildren: () => import('./caregiver/caregiverPages.module').then(m => m.CaregiverPagesModule),
        canActivate: [AuthGuard, CaregiverGuard]
    },
    {
        path: 'guardian',
        loadChildren: () => import('./guardian/guardianPages.module').then(m => m.GuardianPagesModule),
        canActivate: [AuthGuard, CaregiverGuard]
    },
    {
        path: 'facilityadmin',
        loadChildren: () => import('./facilityAdmin/facilityAdminPages.module').then(m => m.FacilityAdminPagesModule),
        canActivate: [AuthGuard, FacilityAdminGuard]
    },
    {
        path: 'organizationadmin',
        loadChildren: () => import('./organizationAdmin/orgadminPages.module').then(m => m.OrganizationAdminPagesModule),
        canActivate: [AuthGuard, OrgAdminGuard]
    },
    {
        path: 'principleinvestigator',
        loadChildren: () => import('./principleInvestigator/principleInvestigatorPages.module').then(m => m.PrincipleInvestigatorPagesModule),
        canActivate: [AuthGuard] //TODO: create/add PI AuthGuard
    },
    {
        path: 'researchcoordinator',
        loadChildren: () => import('./researchCoordinator/researchCoordinatorPages.module').then(m => m.ResearchCoordinatorPagesModule),
        canActivate: [AuthGuard] //TODO: create/add RC AuthGuard
    },
    {
        path: 'researcher',
        loadChildren: () => import('./researcher/researcherPages.module').then(m => m.ResearcherPagesModule),
        canActivate: [AuthGuard] //TODO: create/add Researcher AuthGuard
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/adminPages.module').then(m => m.AdminPagesModule),
        canActivate: [AuthGuard, SiteAdminGuard]
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    },
    //TODO: refactor 'facility' and 'research' to be all verification routes
    {
        path: 'facility',
        loadChildren: () => import('./facility/facility.module').then(m => m.FacilityModule),
    },
    {
        path: 'research',
        loadChildren: () => import('./research/researchVerify.module').then(m => m.ResearchVerifyModule),
    },
    {
        path: '',
        // redirectTo cannot be used with canActivate:
        // https://blog.ninja-squad.com/2021/01/21/what-is-new-angular-11.1/
        redirectTo: '/user/dashboard',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/user/dashboard',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: process.env.NODE_ENV === 'development' } // <-- debugging purposes only
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
