import { NgModule, ApplicationRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DirectivesModule } from '../components/directives.module';
import { ServicesModule } from '../services/services.module';
import { JwtModule } from '@auth0/angular-jwt';

import { AccountModule } from './account/account.module';
import { FacilityModule } from './facility/facility.module';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { MarkAsteriskDirectiveModule } from '../components/directives/mark-asterisk.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {UserPagesModule} from './user/userPages.module';
import { FacilityAdminPagesModule } from './facilityAdmin/facilityAdminPages.module';
import { CaregiverPagesModule } from './caregiver/caregiverPages.module';
import { OrganizationAdminPagesModule } from './organizationAdmin/orgadminPages.module';
import {GuardianPagesModule} from './guardian/guardianPages.module';
import { PrincipleInvestigatorPagesModule } from './principleInvestigator/principleInvestigatorPages.module';
import { ResearchCoordinatorPagesModule } from './researchCoordinator/researchCoordinatorPages.module';
import { ResearcherPagesModule } from './researcher/researcherPages.module';
import {AuthModule} from '../components/auth/auth.module';
import { QuillModule } from 'ngx-quill';

export function tokenGetter() {
    return localStorage.getItem('id_token');
}

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin
]);


@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            headerName: 'x-csrf-token'
        }),
        BrowserAnimationsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter
            }
        }),
        BsDatepickerModule.forRoot(),
        AuthModule.forRoot(),
        QuillModule.forRoot(),
        ReactiveFormsModule,
        // RouterModule.forRoot(appRoutes, {
        //     enableTracing: process.env.NODE_ENV === 'development'
        // }),
        AppRoutingModule,
        FontAwesomeModule,
        DirectivesModule,
        ServicesModule,
        AccountModule,
        FacilityModule,
        FullCalendarModule,
        MarkAsteriskDirectiveModule,
        UserPagesModule,
        CaregiverPagesModule,
        FacilityAdminPagesModule,
        OrganizationAdminPagesModule,
        GuardianPagesModule,
        PrincipleInvestigatorPagesModule,
        ResearchCoordinatorPagesModule,
        ResearcherPagesModule,
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
})
export class AppModule {
    static parameters = [ApplicationRef];
    constructor(public appRef: ApplicationRef) {
        this.appRef = appRef;
    }
}
